import React, { useEffect, useState, useRef } from 'react'
import "../style/case.less"
import Footer from "../components/footer"
import Header from '../components/header'
import { navigate } from "gatsby"
import listImg1 from "../images/case/caseimg1.jpeg"
import listImg2 from "../images/case/caseimg2.jpeg"
import listImg3 from "../images/case/caseimg3.jpeg"
import listImg4 from "../images/case/caseimg1.jpeg"
import arrow from "../images/case/arrow.png"
import Fade from "react-reveal/Fade"
export default function Case() {
    const caseTitle = useRef(null)
    const [caseShadow, setCaseSgadiw] = useState(false)
    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            setCaseSgadiw(entries[0].isIntersecting)
        }, {
            threshold: [1]
        })
        observer.observe(caseTitle.current)
    }, [])
    const caseList = [
        { id: 0, img: listImg1, title: "Public Health Agency of Canada", text: "PHAC leveraged the Sightline Datatrust and AI platform technology to monitor mental health and behavioural patterns around suicide ideation and ideation risk. It used social media data sources to monitor & predict (with AI) aggregate level mental health risk across Canada. The outcome was a privacy compliant real time monitoring system for suicide ideation risk. PHAC is now able to utilize new data sources such as social media." },
        { id: 1, img: listImg2, title: "Real Time Medical", text: "Leveraging private and public datasets, with the Sightline AI & Datatrust platform, a recommendation engine was deployed for radiologists that ensures all patient data is protected. This system helped them to better understand the care needed for their patients, improve their knowledge around best practices and optimize scheduling of caseload." },
        { id: 2, img: listImg3, title: "Safe at Work", text: "Safe at Work is an IOT based application designed to manage the spread of COVID-19 in the workplace. It creates a unique digital twin representation of individual behaviour in a privacy preserving manner. This digital twin is used to create exposure models for individuals based on their behaviour and contact. To collaborate with critical personal data in a privacy preserving, compliant and secure manner." },
        { id: 3, img: listImg4, title: "City of Toronto", text: "The City of Toronto contracted Sightline Innovation to explore the use of datatrust technology for various Connected Community use cases. The final selected use case for the datatrust project was “Integrated Community Services”. Sightline Innovation, in partnership with Cisco Systems Canada, utilized the Sightline datatrust platform to explore how the current challenges around community benefits and service delivery could be transformed to benefit both the citizens as well as internal city stakeholders." }
    ]

    return (
        <>
            <div className='case'>
                <Header />
                <h1 className={caseShadow ? "shadow" : ""} ref={caseTitle}>Case</h1>
                <p>Data security and protection for Healthcare</p>
                <ul>
                    {
                        caseList.map((item, index) => {
                            return (
                                <li key={item.id}>
                                    <div className='img'>
                                        <div onClick={() => navigate("/caseDetail?id=" + index)}><img src={item.img} alt="" /></div>
                                        <div className='title'>
                                            <span>medical</span>
                                            <Fade distance={"20%"} top duration={1300}  >
                                                <h3>{item.title}</h3>
                                            </Fade>
                                        </div>
                                    </div>

                                    <div className='client'>
                                        <div>
                                            <span>Client</span>
                                            <span>Government of Canada</span>
                                        </div>
                                        <hr />
                                        <div>
                                            <span>product</span>
                                            <span>Securely collaborate</span>
                                        </div>
                                    </div>
                                    <p>{item.text}</p>
                                    <div onClick={() => navigate("/caseDetail?id=" + index)} className="arrow">
                                        <img src={arrow} alt="" />
                                        <a href={"/caseDetail?id=" + index} target="_blank" ></a>
                                        </div>
                                </li>
                            )
                        })
                    }

                </ul>
            </div>

            <Footer />
        </>
    )
}
